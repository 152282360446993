<template>
  <div class="publicFilter">
    <!-- 左侧 -->
    <div class="publicFilter-left">
      <!-- 列表 -->
      <div class="publicFilter-list">
        <div class="publicFilter-item" v-for="(item, key) in filter" :key="key">
          <div class="publicFilter-item-title">{{ item.title }}：</div>
          <div class="publicFilter-item-selector">
            <div
              class="publicFilter-item-selector-element"
              :class="{
                'publicFilter-item-selector-element_active':
                  selector.id == filterData[key],
              }"
              v-for="selector in item.list"
              :key="selector.id"
              @click="filterSelect(key, selector.id)"
            >
              {{ selector.name }}
            </div>
          </div>
        </div>
      </div>
      <!-- 确认 -->
      <div class="publicFilter-submit" @click="confirm">确认</div>
    </div>
    <div class="publicFilter-right">
      <div class="publicFilter-tag">
        <div
          class="publicFilter-tag-element"
          :class="{
            'publicFilter-tag-element_active': tagData[index],
          }"
          v-for="(item, index) in tag"
          :key="item.id"
          @click="tagSelect(index)"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- 确认 -->
      <div class="publicFilter-submit" @click="confirm">确认</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "publicFilter",
  props: {
    filter: Object,
    tag: Array,
  },
  watch: {
    filter(filter) {
      Object.keys(filter).forEach((key) => {
        this.filterData[key] = "";
      });
    },
    tag(tag) {
      this.tagData = new Array(tag.length).fill(false);
    },
  },
  data() {
    return {
      filterData: {},
      tagData: [],
    };
  },
  methods: {
    /**
     * 选择过滤器
     */
    filterSelect(key, value) {
      this.filterData[key] = this.filterData[key] == value ? "" : value;
    },

    /**
     * 确认
     */
    confirm() {
      this.$emit("confirm", {
        filter: this.filterData,
        tag: this.tag
          .reduce((result, item, index) => {
            if (this.tagData[index]) {
              result.push(item.id);
            }
            return result;
          }, [])
          .join(","),
      });
    },

    /**
     * 选择标签
     */
    tagSelect(index) {
      this.tagData[index] = !this.tagData[index];
      // this.$emit("tagChange", index);
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .publicFilter {
    width: 1200px;
    margin: auto;
    // color: #ed207b;
    border-radius: 10px;
    border: 1px dashed #ed207b;
    display: flex;

    // 提交
    &-submit {
      height: 56px;
      width: 180px;
      color: #fff;
      background-color: #ed207b;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      font-size: 20px;
      font-weight: bold;
      margin: auto;
      margin-top: 30px;
      cursor: pointer;
    }

    // 左侧
    &-left {
      padding: 20px;
      width: 0;
      flex: 1;
    }

    &-right &-submit {
      display: none;
    }

    // 右侧
    &-right {
      width: 300px;
      border-left: 1px dashed #c6c6c6;
    }

    // 列表
    &-list {
      display: flex;
      flex-direction: column;
    }

    // 列表元素
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      // 标题
      &-title {
        color: #323232;
        font-size: 16px;
        margin-right: 30px;
        width: 100px;
      }

      // 列表元素-选择器
      &-selector {
        display: flex;

        // 列表元素-选择器-元素
        &-element {
          width: 120px;
          margin-right: 32px;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          font-size: 16px;
          box-sizing: border-box;
          color: #ed207b;
          border: 1px solid #ed207b;
          cursor: pointer;

          // 选中
          &_active {
            background-color: #ed207b;
            color: #fff;
          }
        }
      }
    }

    // 标签
    &-tag {
      display: flex;
      flex-wrap: wrap;
      padding: 20px 10px;

      // 标签-元素
      &-element {
        margin-right: 10px;
        margin-bottom: 10px;
        color: #b6b6b6;
        background-color: #f8f8f8;
        border: 1px solid #e6e6e6;
        color: #b6b6b6;
        height: 28px;
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 0 12px;
        font-size: 14px;

        // 选中
        &_active {
          background-color: #ed207b;
          border-color: #ed207b;
          color: #fff;
        }
      }
    }
  }
}
</style>


<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .publicFilter {
    width: stretch;
    margin: rpx(20);
    // color: #ed207b;
    border-radius: rpx(20);
    border: rpx(2) dashed #ed207b;

    // 提交
    &-submit {
      height: rpx(70);
      width: rpx(200);
      color: #fff;
      background-color: #ed207b;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: rpx(8);
      font-size: rpx(30);
      font-weight: bold;
      margin: auto;
      margin-bottom: rpx(40);
      cursor: pointer;
    }

    // 左侧
    &-left {
      padding: rpx(30);
    }

    // 右侧
    &-right {
      // width: 300px;
      border-top: rpx(2) dashed #c6c6c6;
    }

    &-left &-submit {
      display: none;
    }

    // 列表
    &-list {
      display: flex;
      flex-direction: column;
    }

    // 列表元素
    &-item {
      margin-bottom: rpx(30);

      // 标题
      &-title {
        color: #323232;
        font-size: rpx(30);
      }

      // 列表元素-选择器
      &-selector {
        display: flex;
        flex-wrap: wrap;
        margin-top: rpx(20);

        // 列表元素-选择器-元素
        &-element {
          width: rpx(152);
          height: rpx(60);
          margin-right: rpx(12);
          margin-bottom: rpx(20);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: rpx(8);
          font-size: rpx(24);
          box-sizing: border-box;
          color: #ed207b;
          border: 1px solid #ed207b;
          cursor: pointer;

          &:nth-of-type(4n + 0) {
            margin-right: 0;
          }

          // 选中
          &_active {
            background-color: #ed207b;
            color: #fff;
          }
        }
      }
    }

    // 标签
    &-tag {
      display: flex;
      flex-wrap: wrap;
      padding: rpx(20) rpx(10);

      // 标签-元素
      &-element {
        color: #b6b6b6;
        background-color: #f8f8f8;
        border: rpx(2) solid #e6e6e6;
        color: #b6b6b6;
        height: rpx(50);
        margin: rpx(10);
        border-radius: rpx(25);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 0 rpx(24);
        font-size: rpx(28);

        // 选中
        &_active {
          background-color: #ed207b;
          border-color: #ed207b;
          color: #fff;
        }
      }
    }
  }
}
</style>
