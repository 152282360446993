<template>
  <router-link
    :to="{ name: 'caregiverDetail', query: { id: detail.id } }"
    class="caregiverCard"
  >
    <div class="caregiverCard-sign" v-if="detail.signupdata == 1">
      <img src="@/assets/image/public/sign.png" />
    </div>
    <div class="caregiverCard-cover">
      <img :src="detail.photo" />
    </div>
    <div class="caregiverCard-info">
      <div class="caregiverCard-lv">
        <img src="@/assets/image/public/lv.png" />
        <span>{{ detail.servicelevel }}</span>
      </div>
      <div class="caregiverCard-detail">
        <div class="caregiverCard-header">
          <div>{{ detail.name }}</div>
          <div>{{ detail.hometown }}</div>
          <div>{{ detail.age }}岁</div>
        </div>
        <div class="caregiverCard-footer">
          <div class="caregiverCard-hot">
            <img src="@/assets/image/public/hot.png" /> {{ detail.hot }}
          </div>
          <div class="caregiverCard-evaluate">
            <img src="@/assets/image/public/evaluate.png" />
            {{ detail.goodevl }}
          </div>
        </div>
      </div>
    </div>
    <div class="caregiverCard-star">
      <iconfont
        v-for="item in 5"
        :key="item"
        class="caregiverCard-star-icon"
        size="26px"
        :name="item <= detail.starrating ? 'star-full' : 'star'"
      />
    </div>
    <div class="caregiverCard-certificates">
      <div class="caregiverCard-certificates-element" v-for="(item) in detail.cardstr" :key="item">{{item}}</div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "caregiver-card",
  props: {
    detail: Object,
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  // 月嫂卡片
  .caregiverCard {
    width: 280px;
    height: 270px;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #eaeaea;
    position: relative;
    background-color: #fff;
    transition: background-color 500ms;

    &:hover {
      background-color: #fafafa;
    }

    & > div {
      transition-duration: 500ms;
      transition-property: transform, opacity;
    }

    &-cover {
      width: 280px;
      height: 190px;

      img {
        @include cover;
        object-fit: cover;
      }
    }

    &:hover &-cover {
      transform: translateY(-100%);
      opacity: 0;
    }

    &-info {
      display: flex;
      align-items: center;
      height: 80px;
    }

    &-sign {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 11;
    }

    &-detail {
      width: 0;
      flex: 1;
    }

    &:hover > &-info {
      transform: translateY(-140px);
    }

    &-lv {
      width: 70px;
      height: 70px;
      color: #ed207b;
      position: relative;
      z-index: 11;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        @include cover;
        width: 34px;
        height: 34px;
      }

      span {
        position: absolute;
        bottom: 15px;
        right: 20px;
        font-size: 20px;
        font-style: italic;
      }
    }

    &-header {
      font-size: 16px;
      display: flex;
      justify-content: center;
      color: #333333;

      div:not(:last-of-type)::after {
        content: "|";
        display: inline-block;
        margin: 0 5px;
      }
    }

    &-footer {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }

    &-hot,
    &-evaluate {
      font-size: 16px;
      display: flex;
      align-items: center;
      margin: 0 10px;

      img {
        display: block;
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }

    &-hot {
      color: #ed207b;
    }

    &-evaluate {
      color: #9bdbda;
    }

    &-star {
      display: flex;
      justify-content: center;
      margin-top: 5px;

      &-icon {
        margin: 0 2px;
        color: #ed207b;
      }
    }

    &:hover &-star {
      transform: translateY(-140px);
    }

    &-certificates {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      &-element {
        color: #fff;
        background-color: #ed207b;
        padding: 0 6px;
        line-height: 28px;
        font-size: 16px;
        border-radius: 5px;
        margin: 0 8px;
      }
    }

    &:hover &-certificates {
      transform: translateY(-140px);
    }
  }
}
</style>


<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  // 月嫂卡片
  .caregiverCard {
    width: rpx(326);
    height: rpx(315);
    overflow: hidden;
    border-radius: rpx(20);
    border: rpx(2) solid #eaeaea;
    position: relative;
    background-color: #fff;

    &-cover {
      width: rpx(325);
      height: rpx(220);

      img {
        @include cover;
        object-fit: cover;
      }
    }

    &-info {
      display: flex;
      align-items: center;
      height: rpx(90);
    }

    &-sign {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 11;
      width: rpx(72);
      height: rpx(72);
      // object-fit: contain;

      img {
        width: 100%;
        height: 100%
      }
    }

    &-detail {
      width: 0;
      flex: 1;
    }

    &-lv {
      width: rpx(70);
      height: rpx(70);
      color: #ed207b;
      position: relative;
      z-index: 11;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        @include cover;
        width: rpx(50);
        height: rpx(50);
      }

      span {
        position: absolute;
        bottom: rpx(10);
        right: rpx(15);
        font-size: rpx(20);
        font-style: italic;
      }
    }

    &-header {
      font-size: rpx(24);
      display: flex;
      justify-content: center;
      color: #333333;

      div:not(:last-of-type)::after {
        content: "|";
        display: inline-block;
        margin: 0 rpx(10);
      }
    }

    &-footer {
      display: flex;
      justify-content: center;
      margin-top: rpx(10);
    }

    &-hot,
    &-evaluate {
      font-size: rpx(24);
      display: flex;
      align-items: center;
      margin: 0 rpx(10);

      img {
        display: block;
        width: rpx(32);
        height: rpx(32);
        margin-right: rpx(20);
      }
    }

    &-hot {
      color: #ed207b;
    }

    &-evaluate {
      color: #9bdbda;
    }

    &-star {
      display: flex;
      justify-content: center;
      margin-top: 5px;

      &-icon {
        margin: 0 2px;
        color: #ed207b;
      }
    }

    &-certificates {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      &-element {
        color: #fff;
        background-color: #ed207b;
        padding: 0 6px;
        line-height: 28px;
        font-size: 16px;
        border-radius: 5px;
        margin: 0 8px;
      }
    }
  }
}
</style>
